import React, { Component } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

let Iframe = props => {
  return (
    <iframe
      src={props.src}
      height={props.height}
      width={props.width}
      frameborder="0"
      allowfullscreen
    ></iframe>
  )
}

export default class Contact extends Component {
  render() {
    return (
      <Layout>
        <Seo
          title="Contact Us"
          description="Get in touch with The Scholastic Art & Writing Awards"
        />

        <div className="static">
          <section id="static-no-hero">&nbsp;</section>
          <section id="static-page" className="page-pad">
            <div className="page">
              <h2 style={{border:"none"}}>Contact Us</h2>

              <Iframe
                src="https://vdih7mg0tn.mfs.gg/xyR84E-"
                height="1200"
                width="100%"
              />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
